<template>
	<div class="users-main-shell">
		<div class="container">
			<login-form/>
		</div>
	</div>
</template>

<script>
import LoginForm from './LoginForm.vue';

export default {
	components: { LoginForm }
}
</script>