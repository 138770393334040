<template>
	<div class="container">
		<div class="row justify-center">
			<div class="col-md-8">
				<div class="panel">
					<div class="panel-title">
						<div class="panel-heading">{{ newUser ? 'Create New Account' : 'Login' }}</div>
					</div>
					<div class="panel-body">
						<ui-notice v-if="error" type="warning" icon="fas fa-exclamation-circle" msg="Invalid username or password!"/>

						<ui-form ref="form">
							<ui-text-box-field
								v-model="info.username"
								:rules="rules.username"
								label="Username"
								prepend-icon="fas fa-user"
								autofocus
							/>

							<ui-text-box-field
								v-model="info.password"
								:rules="rules.password"
								password
								label="Password"
								prepend-icon="fas fa-lock"
							/>

							<template v-if="newUser">
								<div class="row">
									<div class="col-xs-12">
										<ui-text-box-field
											password
											v-model="newUserInfo.password_confirmation"
											:rules="rules.conf_password"
											label="Confirm Password"
											prepend-icon="fas fa-lock" 
										/>
									</div>

									<div class="col-md-6">
										<ui-text-box-field
											label="First Name"
											:rules="rules.first_name"
											v-model="newUserInfo.first_name"
										/>
									</div>

									<div class="col-md-6">
										<ui-text-box-field
											label="Last Name"
											:rules="rules.last_name"
											v-model="newUserInfo.last_name"
										/>
									</div>

									<div class="col-md-6">
										<ui-select-field
											label="Gender"
											empty-text="(select an option)"
											empty-value=""
											:items="genders"
											:rules="rules.gender"
											v-model="newUserInfo.gender" 
										/>
									</div>

									<div class="col-md-6">
										<ui-date-picker
											label="Date of Birth"
											:rules="rules.date_of_birth"
											v-model="newUserInfo.date_of_birth" 
										/>
									</div>

									<div class="col-md-6">
										<ui-select-field
											label="Cart Preference"
											empty-text="None"
											:empty-value="0"
											:items="$options.cartOptions"
											item-text="abbrev"
											item-value="id"
											v-model="newUserInfo.pref_cart_id" 
										/>
									</div>

									<div class="col-xs-12">
										<ui-text-box-field
											label="Email Address"
											:rules="rules.email"
											v-model="newUserInfo.primary_email"
										/>
									</div>
								</div>
							</template>
						</ui-form>
					</div>
					<div class="panel-footer">
						<template v-if="newUser">
							<ui-button :stacked="$breakpoints.smAndDown" color="primary" :loading="loading" @click="createUser">Create Account</ui-button>
							<span class="flex-spacer"></span>
							<ui-button  :stacked="$breakpoints.smAndDown" color="text" @click="newUser = false">Cancel</ui-button>
						</template>

						<template v-else>
							<ui-button :stacked="$breakpoints.smAndDown" color="primary" @click="submit" :loading="loading">Submit</ui-button>
							<span class="flex-spacer"></span>
							<ui-button v-if="allowRegistration" :stacked="$breakpoints.smAndDown" color="text" @click="newUser = true">Create Account</ui-button>
							<ui-button :stacked="$breakpoints.smAndDown" color="text" href="/users/recovery/forgot_password">Forgot Password?</ui-button>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { createApi } from 'igolf/shared/api';

import validate from 'igolf/shared/validators';

const api = createApi('/users');

export default {
	cartOptions: window._initData.carts,

	data()
	{
		return {
			error: false,
			loading: false,
			newUser: false,
			redirectUrl: null,
			allowRegistration: false,
			account: {},

			genders: [
				{ text: 'Male', value: 'male' },
				{ text: 'Female', value: 'female' },
				{ text: 'Non-specified / Other', value: 'other' },
			],

			info: {
				username: '',
				password: '',
			},

			newUserInfo: {
				password_confirmation: '',
				first_name: '',
				last_name: '',
				primary_email: '',
				date_of_birth: '',
				gender: '',
			},

			rules: {
				username: [ v => !!v || 'Please enter your username' ],
				first_name: [ v => !!v || 'Please enter your first name' ],
				last_name: [ v => !!v || 'Please enter your last name' ],
				gender: [ v => !!v || 'Please select a gender' ],
				date_of_birth: [ v => !!v || 'Please specify your date of birth' ],

				email: [
					v => !!v || 'Please enter your email address',
					v => validate.email(v) || 'Invalid email address',
				],

				password: [ 
					v => !!v || 'Please enter your password',
					v => validate.password(v) || 'Passwords must be six characters or longer'
				],

				conf_password: [ 
					v => !!v || 'Please re-enter your password',
					v => v == this.info.password || 'Passwords do not match',
				],

			}
		}
	},

	methods: {
		resetForm()
		{
			this.info = {
				username: '',
				password: '',
			};

			this.newUserInfo = {
				password_confirmation: '',
				first_name: '',
				last_name: '',
				primary_email: '',
				date_of_birth: '',
				gender: '',
			};
		},

		async createUser()
		{
			if (!this.$refs.form.validate())
				return;
			
			this.loading = true;

			try
			{
				var user = { ...this.info, ...this.newUserInfo };

				await api.post('/api/registration', { user });

				document.location = this.redirectUrl || '/users/courses';
			}
			finally
			{
				this.loading = false;
			}
		},

		async submit()
		{
			if (!this.$refs.form.validate())
				return;

			this.loading = true;
			this.error = false;

			try
			{
				var result = await api.post('/authenticate', this.info);
				
				this.error = !result.data.success;

				if (!this.error)
				 	document.location = this.redirectUrl || '/users/courses';
			}
			finally
			{
				this.loading = false;
			}
		}
	},

	created()
	{
		this.allowRegistration = window._initData.hasPublicCourses;
		this.account = { ...window._initData.account };
		this.redirectUrl = window._initData.redirectUrl;
	}
}
</script>