
import 'styles/users.scss';

import Vue from 'vue';

// import common modules
import 'igolf/ux';
import 'igolf/shared/breakpoints';

// import router and main admin shell
import LoginMain from 'igolf/users/login/LoginMain.vue';

const app = new Vue({
	el: '#app-root',

	render(h) {
		return h(LoginMain);
	}
})


